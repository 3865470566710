import { useEffect } from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ currentPage, totalPages, paginate }) => {

  useEffect(() => {
    // Add swipe support for the whole page
    let touchStartX = 0;
    let touchEndX = 0;
    let touchStartY = 0;
    let touchEndY = 0;

    const handleTouchStart = (e) => {
      touchStartX = e.changedTouches[0].screenX;
      touchStartY = e.changedTouches[0].screenY;
    };

    const handleTouchEnd = (e) => {
      touchEndX = e.changedTouches[0].screenX;
      touchEndY = e.changedTouches[0].screenY;
      handleSwipeGesture();
    };

    const handleSwipeGesture = () => {
      const horizontalDiff = touchEndX - touchStartX;
      const verticalDiff = Math.abs(touchEndY - touchStartY);

      // Only consider it a swipe if the horizontal difference is greater than the vertical difference
      if (Math.abs(horizontalDiff) > verticalDiff) {
        if (horizontalDiff < 0) {
          // Swiped left, go to next page
          if (currentPage < totalPages) paginate(currentPage + 1);
        }
        if (horizontalDiff > 0) {
          // Swiped right, go to previous page
          if (currentPage > 1) paginate(currentPage - 1);
        }
      }
    };

    // Add event listeners to the whole document
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    // Cleanup the event listeners on component unmount
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentPage, totalPages, paginate]);

  // Calculate the range of page numbers to display around the current page
  const lowerLimit = Math.max(1, currentPage - 1);
  const upperLimit = Math.min(totalPages, currentPage + 1);
  const rangeStart = Math.max(1, Math.min(lowerLimit, totalPages - 4));
  const rangeEnd = Math.min(totalPages, Math.max(upperLimit, 5));

  const pages = Array.from({ length: rangeEnd - rangeStart + 1 }, (_, index) => rangeStart + index);

  return (
    <Pagination
      className="m-3 d-flex justify-content-center"
    >
      <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
        &lt;
      </Pagination.Prev>

      {/* First Page */}
      {rangeStart > 1 && (
        <>
          <Pagination.Item onClick={() => paginate(1)}>1</Pagination.Item>
          {rangeStart > 2 && <Pagination.Ellipsis disabled />}
        </>
      )}

      {/* Pages within the calculated range */}
      {pages.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ))}

      {/* Ellipsis and Last Page */}
      {rangeEnd < totalPages && (
        <>
          {rangeEnd < totalPages - 1 && <Pagination.Ellipsis disabled />}
          <Pagination.Item onClick={() => paginate(totalPages)}>{totalPages}</Pagination.Item>
        </>
      )}

      <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
        &gt;
      </Pagination.Next>
    </Pagination>
  );
};

export default PaginationComponent;