import React from "react";

const MarketplaceHeader = () => {
  return (
    <>
      <h2>
        Welcome to the First Free & Independent Private Hire Driver Information
        Marketplace
      </h2>
      <br />
      <h3 className="mb-4">
        We are unpacking the market for drivers. Interested in finding out more,
        select a topic below.
      </h3>
      <br />
    </>
  );
};

export default MarketplaceHeader;