import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

const MenuModal = ({
  showMenu = () => {},
  currentMenu = {},
  currentVertical = {},
  handleMenuClose = () => {},
  handleOptionClick = () => {}
}) => {
  return (
    <Modal show={showMenu} onHide={handleMenuClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentMenu?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentMenu?.img_src && (
          <img
            src={currentMenu.img_src}
            alt={currentMenu.title}
            style={{
              width: "100%",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
          />
        )}
        {currentMenu?.content ? (
          <p>{currentMenu.content}</p>
        ) : (
          <div className="htmlContentMenuBox">
            <div dangerouslySetInnerHTML={{ __html: currentMenu?.html_content }} />
          </div>
        )}
        {currentMenu?.options && currentMenu.options.length > 0 && (
          <Row className="d-flex justify-content-center">
            {currentMenu.options.map((option, index) => (
              <Col
                key={index}
                md={currentMenu.layout === "horizontal" ? 4 : 12}
                className="d-flex justify-content-center"
                style={{ marginBottom: "25px" }}
              >
                <Button
                  variant="warning"
                  style={{
                    width: currentMenu.layout === "horizontal" ? "100%" : "80%",
                    padding: "10px",
                  }}
                  onClick={() => handleOptionClick(option.value, option.key, currentMenu.sub_menus?.[option.key]?.is_function, currentVertical.functions)}
                >
                  {option.value}
                </Button>
              </Col>
            ))}
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MenuModal;