import { useState, useEffect } from "react";
import ApiHost from '../ApiHost'

const useFetchData = () => {
  const [verticals, setVerticals] = useState([]);
  const [newsItems, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const environment = process.env.REACT_APP_ENVIRONMENT || 'staging';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        let queryString = environment === 'staging' ? 'staging=1' : '';
        let response = await fetch(`${ApiHost}/v1/get-verticals?${queryString}`);
        if (!response.ok) throw new Error("Failed to fetch verticals.");
        let data = await response.json();
        setVerticals(data);

        response = await fetch(`${ApiHost}/v1/get-news-articles?${queryString}`);
        if (!response.ok) throw new Error("Failed to fetch news articles.");
        data = await response.json();
        setNews(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Unable to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [environment]);

  return { verticals, newsItems, loading, error };
};

export default useFetchData;