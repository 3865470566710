import React, { useState } from "react";
import { Container, Row, Col, Card, Spinner, Alert } from "react-bootstrap";
import NewsCarousel from "./NewsCarousel";
import MenuModal from "./MenuModal";
import useFetchData from "../../hooks/useFetchData"; // Import custom hook
import MarketplaceHeader from "./MarketplaceHeader";
import SupplierSearchModal from '../Suppliers/SupplierSearchPageModal';


const Home = ({handleQuestionShow}) => {
  // State for managing menu modal visibility and current menu
  const [showMenu, setShowMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [showSearchProviderModalModal, setSearchProviderModalShowModal] = useState(false);
  const [searchParameters, setSearchParameters] = useState(null);
  const [searchProviderData, setSearchProviderData] = useState(null);
  const [currentVertical, setCurrentVertical] = useState(null);
  const handleSearchProviderModalShow = () => setSearchProviderModalShowModal(true);
  const handleSearchProviderModalClose = () => setSearchProviderModalShowModal(false);
  // Fetch verticals and newsItems from the custom hook
  const { verticals, newsItems, loading, error } = useFetchData();

  // Functions for menu management
  const handleMenuShow = (card) => {
    setCurrentMenu(card);
    setShowMenu(true);
    setCurrentVertical(card);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
    setTimeout(() => { // Introduce a slight delay
      setCurrentMenu(null);
    }, 300);
  };

  const handleSearchSuppliers = async (parameters, data) => {
    setSearchParameters(JSON.stringify(parameters))
    setSearchProviderData(data);
      handleSearchProviderModalShow();
  }

  const handleOptionClick = (option, key, isFunction, functions) => {
    if (isFunction) {
      handleMenuClose(); // Close the menu modal first
        if(key && key.includes("suppliers")) {
          const func = functions.search_suppliers_functions.find(obj => obj.button_id === key);
          handleSearchSuppliers(func.parameters, func.data);
        } else if(key && key.includes("question")) {
            handleQuestionShow();
        }
      
    } else {
      const nextMenu = currentMenu.sub_menus?.[key]; // Check if this option has a submenu
      if (nextMenu) {
        setCurrentMenu(nextMenu); // Pass the submenu object directly
      }
    }
  };

  // Show spinner while loading
  if (loading) {
    return (
      <Container className="text-center my-5">
        <MarketplaceHeader />
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  // Show error message if there's an error
  if (error) {
    return (
      <Container className="text-center my-5">
        <MarketplaceHeader />
        <Alert variant="danger">
          <strong>Oops!</strong> Something went wrong. Unable to load data.
          Please try again later.
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <Container className="my-5 text-center">
      <MarketplaceHeader />
        <Row>
          {verticals
            .sort((a, b) => a.order - b.order)
            .map((card) => (
              <Col key={card.key} md={2}>
                <Card
                  className="p-6"
                  style={{
                    borderRadius: "25px",
                    border: "0",
                    color: "#030765",
                  }}
                  onClick={() => handleMenuShow(card)}
                >
                  <Card.Img
                    variant="top"
                    src={card.icon_url}
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "25px",
                    }}
                  />
                  <Card.Body>
                    <Card.Title>{card.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <Row>
          <NewsCarousel news={newsItems} />
        </Row>
      </Container>

      {/* Menu Modal */}
      <MenuModal
        showMenu={showMenu}
        currentMenu={currentMenu}
        currentVertical={currentVertical}
        handleMenuClose={handleMenuClose}
        handleOptionClick={handleOptionClick}
      />
      <SupplierSearchModal
        show={showSearchProviderModalModal}
        handleClose={handleSearchProviderModalClose}
        parameters={searchParameters}
        providerData={searchProviderData}
      />
    </>
  );
};

export default Home;