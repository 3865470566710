import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="bg-light py-3" style={{ fontSize: "0.9rem" }}>
      <Container>
        <Row className="mb-4">
          <Col md={12}>
            <p>
              <strong>Important information on how this site works</strong>
            </p>
            <p>
              We want to be transparent on the strengths and limitations of this
              site. We’re an information and editorial website and aim to
              provide information, tips, tools, and techniques. However, we
              can’t guarantee the information is perfect or error free, so note
              you use the information at your own risk and we can’t accept
              liability if things go wrong.
            </p>
            <p>
              The information on this site does not constitute financial advice.
              It’s essential that you always conduct your own research to ensure
              it’s right for your specific circumstances. While we always aim to
              give you accurate information at the point of publication,
              unfortunately price and terms of services, products and deals can
              always be changed by the provider afterwards, so double check
              first.
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={6} className="d-flex justify-content-start">
            <p className="mb-0 fw-bold">
              © 2024 DriverHQ. All rights reserved
            </p>
          </Col>
          <Col md={6} className="d-flex justify-content-end fw-bold">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a href="#Home" className="link-responsive">
                  Home
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#Terms" className="link-responsive">
                  Terms & conditions
                </a>
              </li>
              <li className="list-inline-item">
                <a href="#Privacy" className="link-responsive">
                  Privacy policy
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;