import React from "react";
import { Row, Col, Button } from "react-bootstrap";

const Header = ({ onAskQuestion }) => {
  return (
    <div
      className="header-background text-white text-center py-3"
      style={{ width: "100%", margin: 0 }}
    >
      <div className="logo">
        <img alt="logo" src="img/logo.png"/>
      </div>

      {/* Ask a Question Button */}
      <Row
        className="d-flex flex-wrap justify-content-center no-gutters"
        style={{ marginLeft: "0", marginTop: "2em", maxWidth: "100%" }}
      >
        <Col
          md="auto"
          className="d-flex justify-content-center"
          style={{ marginBottom: "1em" }}
        >
          <Button variant="warning" onClick={onAskQuestion}>
            Ask a question
          </Button>
        </Col>

        <Col
          md="auto"
          className="d-flex justify-content-center"
          style={{ marginBottom: "1em" }}
        >
          <Button className="text-light" variant="success">Join our newsletter</Button>
        </Col>

        <Col
          md="auto"
          className="d-flex justify-content-center"
          style={{ marginBottom: "1em" }}
        >
          <Button className="" variant="light">Become a supplier</Button>
        </Col>
      </Row>
    </div>
  );
};

export default Header;