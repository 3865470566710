import React from "react";
import { Container, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container className="text-center my-5">
        <Alert variant="danger">
          <strong>Oops!</strong> The page you're looking for doesn't exist.
        </Alert>
      <Link to="/Home">
        <Button variant="primary">Go Back to Home</Button>
      </Link>
    </Container>
  );
};

export default NotFound;