import React, { useState } from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom"; // Updated import
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Pages/Home/Home";
import NotFound from "./Pages/Errors/NotFound"; // Import the NotFound component
import QuestionForm from "./Pages/Home/QuestionForm";

const App = () => {
  const [showQuestion, setShowQuestion] = useState(false);
  const handleQuestionShow = () => setShowQuestion(true);

  return (
    <HashRouter> {/* Changed to HashRouter */}
      <Header onAskQuestion={handleQuestionShow} />
      <Routes>
        <Route path="Home" element={<Home handleQuestionShow={handleQuestionShow} />} />
        <Route path="/" element={<Navigate to="Home" />} /> {/* Redirect root to Home */}
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for 404 */}
      </Routes>
      <Footer />
      <QuestionForm show={showQuestion} setShow={setShowQuestion} />
    </HashRouter>
  );  
};

export default App;