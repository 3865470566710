import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Container, Row, Col, Card, Spinner, Alert, Button, Modal, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCar, faGlobe, faPhone,  faTag, faCalendarDays, faAward, faSterlingSign } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faIdBadge, faStar } from '@fortawesome/free-regular-svg-icons';
import ApiHost from '../../ApiHost';
import { Ribbon } from 'react-ribbons';
import PaginationComponent from '../Pagination';
const environment = process.env.REACT_APP_ENVIRONMENT || 'staging';

const SupplierSearchModal = ({
  show = () => {},
  handleClose = () => {},
  parameters = {},
  providerData = {}
}) => {
  const [suppliers, setSuppliers] = useState([]);
  const [filters, setFilters] = useState([]);
  const [icons, setIcons] = useState({});
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const scrollableContentRef = useRef(null);
  const [settings, setSettings] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [suppliersPerPage, setSuppliersPerPage] = useState(5);

  const trackClick = (url) => {
    console.log(`User clicked ${url}`);
  };
  
  // Utility function to get nested values
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  // Mapping FontAwesome icons
  const iconMap = useMemo(() => ({
    faMapMarkerAlt: faMapMarkerAlt,
    faCar: faCar,
    faGlobe: faGlobe,
    faPhone: faPhone,
    faIdBadge: faIdBadge,
    faTag: faTag,
    faClipboard: faClipboard,
    faStar: faStar,
    faCalendarDays: faCalendarDays,
    faAward: faAward,
    faSterlingSign: faSterlingSign
  }), []);

  const [selectedFilter, setSelectedFilter] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterButtonText, setFilterButtonText] = useState('Filters');

  const fetchSuppliers = useCallback(async () => {
    setLoading(true);
    setError(null);
  
    try {
      let queryString = environment === 'staging' ? 'staging=1' : '';
      const response = await fetch(`${ApiHost}/v1/providers?${queryString}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: parameters,
      });
  
      if (!response.ok) throw new Error('Failed to fetch suppliers');
  
      const data = await response.json();
      setSuppliers(data);
      setFilters(providerData.filters);
      setSettings(providerData.settings);
      setCategories(providerData.categories);
      setSuppliersPerPage(providerData.settings.items_per_page);
  
      // Map icons from API data to FontAwesome icons
      const mappedIcons = {};
      for (const [key, category] of Object.entries(providerData.categories)) {
        mappedIcons[key] = iconMap[category.icon];
      }
      setIcons(mappedIcons);
  
      // Initialize the selected filter state based on the filters from API
      const initialFilters = providerData.filters.reduce((acc, filter) => {
        acc[filter.category] = filter.type === 'checkbox' ? [] : null;
        return acc;
      }, {});
      setSelectedFilter(initialFilters);
  
    } catch (err) {
      setError('Unable to load suppliers. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [parameters, providerData, iconMap]);

  useEffect(() => {
    if (show) {
      setCurrentPage(1);
      fetchSuppliers();
    }
  }, [show, parameters, fetchSuppliers]);

  const handleCheckboxChange = (category, value) => {
    setSelectedFilter((prev) => {
      const updatedCategory = prev[category].includes(value)
        ? prev[category].filter((item) => item !== value)
        : [...prev[category], value];
      return { ...prev, [category]: updatedCategory };
    });
    paginate(1);
  };

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
    setFilterButtonText((prev) => (prev === 'Filters' ? 'Close' : 'Filters'));
  };

  const filteredSuppliers = suppliers.filter((supplier) => {
    return filters.every((filter) => {
      const filterCategory = filter.category;
      const supplierValue = getNestedValue(supplier, filterCategory); // Get the nested value from the supplier based on the path

      if (filter.type === 'checkbox') {
        // If the selected filter for this category is empty, we accept all values
        if (selectedFilter[filterCategory].length === 0) {
          return true;
        }

        // If the supplier value is an array, check if any item matches the selected filters
        if (Array.isArray(supplierValue)) {
          return supplierValue.some((item) =>
            selectedFilter[filterCategory].includes(item?.toString())
          );
        }

        if (typeof supplierValue == 'boolean') {
          return supplierValue;
        }

        // If the supplier value is a single value (string/number), check if it matches the selected filter
        return selectedFilter[filterCategory].includes(supplierValue?.toString());
      }

      // Add more conditions for other filter types if needed
      return true;
    });
  });

  const indexOfLastSupplier = currentPage * suppliersPerPage;
  const indexOfFirstSupplier = indexOfLastSupplier - suppliersPerPage;
  const currentSuppliers = filteredSuppliers.slice(indexOfFirstSupplier, indexOfLastSupplier);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (scrollableContentRef.current) {
      scrollableContentRef.current.scrollTop = 0;
    }
  };

  const totalPages = Math.ceil(filteredSuppliers.length / suppliersPerPage);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="ms-auto">{filteredSuppliers.length} Suppliers Found</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mb-4">
            <Col md={12}>
              <Dropdown show={showDropdown} onToggle={handleDropdownToggle}>
                <Dropdown.Toggle style={{ width: '100%' }} variant="warning" id="dropdown-basic" onClick={handleDropdownToggle}>
                  {filterButtonText}
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-3" style={{ maxHeight: '300px', overflowY: 'auto', width: '100%' }}>
                  {filters.map((filter, index) => (
                    <React.Fragment key={index}>
                      <Form.Label>{filter.label}</Form.Label>
                      {[...new Set(
                        suppliers.flatMap((supplier) => {
                          // Get the nested value based on the filter category path
                          const supplierValue = getNestedValue(supplier, filter.category);

                          // Return the appropriate value based on its type
                          if (Array.isArray(supplierValue)) {
                            return supplierValue;
                          } else if (typeof supplierValue === 'string' || typeof supplierValue === 'number') {
                            return [supplierValue];
                          } else if (typeof supplierValue === 'boolean' && supplierValue) {
                            return ["Yes"]; // Return the first option, e.g., "Yes" for booleans
                          }
                          return [];
                        })
                      )].map((option, idx) => (
                        <Form.Group controlId={`${filter.category}-${idx}`} key={`${filter.category}-${idx}`}>
                          <Form.Check
                            type="checkbox"
                            label={option}
                            value={option}
                            checked={selectedFilter[filter.category]?.includes(option)}
                            onChange={() => handleCheckboxChange(filter.category, option)}
                          />
                        </Form.Group>
                      ))}
                      <Dropdown.Divider />
                    </React.Fragment>
                  ))}
                  <Button
                    variant="warning"
                    style={{ width: '100%' }}
                    onClick={() => {
                      setSelectedFilter(
                        filters.reduce((acc, filter) => {
                          acc[filter.category] = filter.type === 'checkbox' ? [] : null;
                          return acc;
                        }, {})
                      );
                      paginate(1);
                      handleDropdownToggle();
                    }}
                  >
                    Clear Filters
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          {loading && (
            <div className="text-center my-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}

          {error && (
            <Alert variant="danger" className="text-center">
              {error}
            </Alert>
          )}

          {!loading && !error && currentSuppliers.length > 0 && (
            <div className="scrollable-content" ref={scrollableContentRef}>
              <Container>
                {currentSuppliers.sort((a, b) => a.name.localeCompare(b.name)).map((supplier) => (
                  <Row key={supplier.id} className="mb-4">
                    <Col>
                      <Card className="position-relative">
                        {settings.show_ribbon && getNestedValue(supplier, settings.field_path) && (
                          <Ribbon
                            side="right"
                            type="corner"
                            size="large"
                            backgroundColor="#ffc107"
                            color="#030765"
                            fontFamily=""
                            withStripes={false}
                          >
                            {settings.ribbon_text}
                          </Ribbon>
                        )}

                        <Card.Body>
                          <Card.Title className="text-center">{supplier.name}</Card.Title>
                          {supplier.logo_url && (
                            <Card.Img src={supplier.logo_url} className="logo-img-provider" />
                          )}
                          <div className="mb-3 mt-3">
                            {Object.keys(icons).map((key) => {
                              const value = key.includes(',')
                                ? key.split(',').map((path) => getNestedValue(supplier, path.trim())).filter(Boolean).join(', ')
                                : getNestedValue(supplier, key);

                              // Check if the value should be displayed based on its type
                              if (categories[key]?.type === 'bool' && !value) {
                                return null; // If it's a boolean and false, don't display anything
                              }

                              return (
                                <React.Fragment key={key}>
                                  {icons[key] && ((value && value.length > 0) || (categories[key]?.type !== 'array' && value)) && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                      <div style={{ minWidth: '20px', marginRight: '10px', textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={icons[key]} />
                                      </div>
                                      <span>
                                        {categories[key]?.type === 'array'
                                          ? value.join(', ') // If it's an array, join with commas
                                          : categories[key]?.type === 'bool'
                                            ? categories[key]?.display_if_true // Display label for true booleans
                                            : value}
                                      </span>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <Row className="d-flex justify-content-center">
                            {supplier.phone_number && (
                              <Col md={4} className="d-flex justify-content-center" style={{ marginBottom: '25px' }}>
                                <a href={'tel:' + supplier.phone_number} style={{ width: '100%' }} onClick={() => trackClick(`tel:${supplier.phone_number}`)}>
                                  <Button variant="warning" style={{ width: '100%' }}>
                                    <FontAwesomeIcon icon={faPhone} />
                                    <span style={{ paddingLeft: '10px' }}>Call</span>
                                  </Button>
                                </a>
                              </Col>
                            )}
                            {supplier.website_url && (
                              <Col md={4} className="d-flex justify-content-center" style={{ marginBottom: '25px' }}>
                                <a href={supplier.website_url} style={{ width: '100%' }} onClick={() => trackClick(supplier.website_url)}>
                                  <Button variant="warning" style={{ width: '100%' }}>
                                    <FontAwesomeIcon icon={faGlobe} />
                                    <span style={{ paddingLeft: '10px' }}>Website</span>
                                  </Button>
                                </a>
                              </Col>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ))}
              </Container>
            </div>
          )}
          {suppliersPerPage < filteredSuppliers.length && (
            <PaginationComponent 
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={paginate}
            />
          )}
          {!loading && !error && filteredSuppliers.length === 0 && (
            <p className="text-center my-4">No suppliers found.</p>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default SupplierSearchModal;