import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const QuestionForm = ({ show, setShow }) => {
  const handleQuestionClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleQuestionClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>How can we help you?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formComments">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Please ask anything"
            />
          </Form.Group>
          <br />
          <Form.Group controlId="formEmail">
            <Form.Control type="email" placeholder="Email" />
          </Form.Group>
          <br />
          <Form.Group controlId="formFirstName">
            <Form.Control type="text" placeholder="First name" />
          </Form.Group>
          <br />
          <Form.Group controlId="formLastName">
            <Form.Control type="text" placeholder="Last name" />
          </Form.Group>
          <br />
          <Form.Group controlId="formPhone">
            <Form.Control type="tel" placeholder="Enter your phone number" />
          </Form.Group>
          <br />
          <Form.Group controlId="formConsent">
            <Form.Check
              type="checkbox"
              label="I consent to using my details to create a DriverHQ profile."
            />
            <br />
            <p>
              For further details, please refer to our{" "}
              <a href="/Privacy">Privacy Policy</a>.
            </p>
          </Form.Group>
          <Button variant="warning" type="submit" style={{ width: "100%" }}>
            Submit (ToDo)
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionForm;